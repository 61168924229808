import { Transition } from '@headlessui/react';
import { mdiClose, mdiLoading, mdiOfficeBuilding } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import { AreYouSureYouDiscardDataPopUp } from 'Components/AreYouSureYouDiscardDataPopUp';
import { FailedSyncPopUp, removeDataFromLocalStorage } from 'Components/FailedSyncPopUp';
import { hasDataToSync, syncDataFromOtherOrganization, UnSyncedDataPopUp } from 'Components/UnSyncedDataPopUp';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import api from 'Services/api';
import auth from 'Services/auth';
import { syncAllData } from 'Services/syncAllData';
import valueStore from 'Services/valueStore';
import useOrganization from 'Support/hooks/useOrganization';
import usePermissions from 'Support/hooks/usePermissions';
import theme from 'tailwind-theme';

const BackgroundOverlay = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="ease-in-out duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  />
);

const PanelWrapper = ({ ...props }) => <div className="absolute inset-y-0 right-0 flex w-72 max-w-screen-9/10" {...props} />;

const Panel = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="transform transition ease-in-out duration-300"
    enterFrom="translate-x-full"
    enterTo="translate-x-0"
    leave="transform transition ease-in-out duration-300"
    leaveFrom="translate-x-0"
    leaveTo="translate-x-full"
    className="relative w-screen max-w-md"
  />
);

const CloseButton = ({ onClick }) => (
  <div className="absolute left-0 top-0 -ml-12 flex pl-4 pr-2 pt-4">
    <button aria-label="Close menu" className="text-gray-300 transition duration-150 ease-in-out hover:text-white" onClick={onClick}>
      <Icon path={mdiClose} size={theme.fontSize['2xl'][0]} />
    </button>
  </div>
);

/**
 * @param {boolean} isOpen
 * @param {function(boolean|function(boolean): boolean)} setOpen
 * @returns {JSX.Element}
 * @constructor
 */
const SwitchOrganizationNav = ({ isOpen, setOpen }) => {
  const closeNav = () => setOpen(false);
  const isOnline = useOnlineStatus();
  const history = useHistory();
  const permissions = usePermissions();
  const { organization_id } = useOrganization();
  const [syncing, setSyncing] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [syncFailed, setSyncFailed] = useState(false);
  const [preventRefresh, setPreventRefresh] = useState(false);
  const [organizationIdSwitchingTo, setOrganizationIdSwitchingTo] = useState(null);
  const [organizationNameSwitchingTo, setOrganizationNameSwitchingTo] = useState(null);
  const [organizationRoleSwitchingTo, setOrganizationRoleSwitchingTo] = useState(null);

  const [discardData, setDiscardData] = useState(null);
  const [unSyncDataDisabled, setUnSyncDataDisabled] = useState(false);
  const [failedSyncOpen, setFailedSyncOpen] = useState(false);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);

  const sync = async () => {
    if (!syncing) {
      setSyncing(true);
      setOrganizations(await api.syncOrganizations());
      setSyncing(false);
    }
  };

  const switchOrganizationAuthentication = async (id, name, role) => {
    await Promise.all([
      valueStore.set('previous_organization', auth.getOrganization()),
      valueStore.set('previous_organization_name', auth.getOrganizationName()),
      auth.setOrganization(id),
      auth.setOrganizationName(name),
      auth.setRole(role),
    ]);
  };

  //run when we select a value on discard data pop up
  useEffect(async () => {
    if (discardData !== null) {
      if (!discardData) {
        await switchOrganizationAuthentication(organizationIdSwitchingTo, organizationNameSwitchingTo, organizationRoleSwitchingTo);
        if (await syncDataFromOtherOrganization()) {
          setFailedSyncOpen(true);
        } else {
          await afterEverything();
        }
      } else {
        setAreYouSureOpen(true);
      }
    }
  }, [discardData]);

  const switchOrganization = async (id, role, name) => {
    setSyncing(true);
    await auth.setPreviousOrganizationName(auth.getOrganizationName());
    setOrganizationIdSwitchingTo(id);
    setOrganizationNameSwitchingTo(name);
    setOrganizationRoleSwitchingTo(role);

    hasDataToSync()
      .then((hasData) => {
        if (hasData) {
          setPreventRefresh(true);
          setSyncFailed(true);
        }
        return Promise.all([switchOrganizationAuthentication(id, name, role), valueStore.delete(`last-sync`), syncAllData(true)]);
      })
      .then(() => {
        history.go(0);
      });
  };

  const afterEverything = async () => {
    setPreventRefresh(false);
    setSyncing(false);
    await valueStore.delete(`last-sync`);
    history.go(0);
  };

  const OrganizationItem = ({ organizationID, organizationName, organizationRole, active }) => (
    <button
      className={cls({
        'bg-gray-100 text-gray-700': active,
        'text-gray-700 hover:bg-gray-100': !active,
        'flex w-full items-center space-x-3 px-4 py-4 text-left text-sm leading-5': true,
      })}
      onClick={(e) => (active ? e.preventDefault() : switchOrganization(organizationID, organizationRole, organizationName))}
    >
      <span className="relative inline-block shrink-0">
        <Icon path={mdiOfficeBuilding} size={1} className="h-5 w-5 text-gray-500" />
        <span className={cls(active ? 'bg-green-400' : 'bg-gray-300', 'absolute right-0 top-0 block h-2.5 w-2.5 rounded-full ring-2 ring-gray-100')} />
      </span>
      <span className="truncate">{organizationName}</span>
    </button>
  );

  useEffect(async () => {
    if (isOnline && permissions.show_organization_switch) {
      await sync();
    }
  }, []);

  const endAreYouSure = async (areYouSureOpen, newDiscardData) => {
    setAreYouSureOpen(areYouSureOpen);
    setDiscardData(newDiscardData);
    if (newDiscardData) {
      await removeDataFromLocalStorage();
      await afterEverything();
    } else {
      setSyncFailed(true);
      setUnSyncDataDisabled(false);
    }
  };

  return (
    <Transition show={isOpen} className="fixed inset-0 z-10 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <BackgroundOverlay show={isOpen} onClick={closeNav} />
        <UnSyncedDataPopUp
          isOpen={syncFailed}
          setOpen={setSyncFailed}
          setValue={setDiscardData}
          disabled={unSyncDataDisabled}
          setDisabled={setUnSyncDataDisabled}
          preventRefresh={preventRefresh}
        />
        <FailedSyncPopUp isOpen={failedSyncOpen} setOpen={setFailedSyncOpen} onSubmit={afterEverything} />
        <AreYouSureYouDiscardDataPopUp isOpen={areYouSureOpen} setOpen={endAreYouSure} />
        <PanelWrapper>
          <Panel show={isOpen}>
            <CloseButton onClick={closeNav} />

            <div className="relative flex h-full flex-col overflow-y-auto bg-white shadow-xl">
              <h4 className="mt-4 px-3 pb-2 text-xs font-semibold uppercase tracking-wider text-gray-900" id="projects-headline">
                Companies
              </h4>
              <hr />
              <div className="relative flex flex-col divide-y divide-gray-200 pb-4">
                {isOnline ? (
                  organizations?.length > 1 &&
                  organizations.map((organization) => (
                    <OrganizationItem
                      key={organization.id}
                      organizationID={organization.id}
                      organizationName={organization.name}
                      organizationRole={organization.pivot?.role?.value}
                      active={organization_id === organization.id}
                    />
                  ))
                ) : (
                  <div className="bg-red-500 p-2 text-center text-white">
                    <span>Please go online to switch organization</span>
                  </div>
                )}
              </div>
            </div>
            {syncing && (
              <div className="absolute left-0 top-0 flex h-full w-full flex-wrap content-center justify-center bg-white bg-opacity-50">
                <Icon path={mdiLoading} size={4} className="animate-spin text-gray-700 " />
              </div>
            )}
          </Panel>
        </PanelWrapper>
      </div>
    </Transition>
  );
};
export default SwitchOrganizationNav;
