import * as Sentry from '@sentry/react';
import imageStore from 'Services/imageStore';
import valueStore from 'Services/valueStore';
import axios from 'axios';
import jsonToFormData from 'json-form-data';
import { v4 as uuidv4 } from 'uuid';
import auth from './auth';

let apiOrigin = window.apiConfig[window.location.origin];
const urlParts = window.apiConfig[window.location.origin].split('.');
if (urlParts[0] === 'https://appserver' && urlParts[urlParts.length - 1] === 'internal') {
  apiOrigin = apiOrigin.replace('appserver.', '');
  apiOrigin = apiOrigin.replace('internal', 'lndo.site');
}

const apiRoot = apiOrigin + window.apiConfig['endpoint'];

axios.defaults.baseURL = apiRoot;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

const authenticatedApi = axios.create();
const unauthenticatedApi = axios.create();

authenticatedApi.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${auth.getToken()}`;
  const organization = auth.getOrganization();
  if (organization) {
    config.headers['X-PWA-CURRENT-ORGANIZATION-ID'] = auth.getOrganization();
  }
  config.headers['X-PWA-VERSION'] = window.version;
  return config;
});

const checkHeaders = (responseHeaders) => {
  auth.handlePermissions(responseHeaders['x-pwa-permission-hash']);

  if (responseHeaders['x-pwa-current-organization-id'] && !auth.getOrganization()) {
    auth.setOrganization(parseInt(responseHeaders['x-pwa-current-organization-id']));
    valueStore.set('previous_organization', auth.getOrganization());
  }
};

const types = {
  report: 'App\\Models\\Report',
  taskAnalysis: 'App\\Models\\HazardReport',
  job: 'App\\Models\\Job',
  task: 'App\\Models\\Task',
  user: 'App\\Models\\User',
};

export default {
  /**
   * @param string email
   * @param string password
   * @returns {Promise<{user: User, token: string, headers: {}}>}
   */
  async login({ email, password }) {
    const {
      data: { token, user },
      headers,
    } = await unauthenticatedApi.post('/authenticate', {
      email,
      password,
      device_name: navigator.userAgent || 'Unidentified Device',
    });

    return { token, user, headers };
  },

  async checkUser({ email }) {
    const { data, headers } = await unauthenticatedApi.post('/check-user', {
      email,
    });

    return data;
  },

  async checkCode(email, code) {
    const { data, headers } = await unauthenticatedApi.post('/check-code', {
      email,
      code,
    });
    return data;
  },

  async setPassword(email, code, password) {
    const { data, headers } = await unauthenticatedApi.post('/set-password', {
      code,
      email,
      password,
    });

    return data;
  },

  /**
   * @returns {Promise<User>}
   */

  async fetchUser() {
    const { data, headers } = await authenticatedApi.get('/user');
    checkHeaders(headers);

    return {
      id: data.id,
      name: data.name,
      email: data.email,
      role: data.role,
      organization_id: data.organization_id,
    };
  },

  async getFile(path) {
    const { data, headers } = await authenticatedApi.get('/generate-path/' + path);
    checkHeaders(headers);
    return data;
  },

  async fetchPermissions() {
    const { data } = await authenticatedApi.get('/permissions');

    return data;
  },

  async fetchUsers() {
    const { data, headers } = await authenticatedApi.get('/users');
    checkHeaders(headers);

    return data;
  },

  async fetchAttendees() {
    const { data, headers } = await authenticatedApi.get('/attendees');
    checkHeaders(headers);

    return data;
  },

  async fetchOrganizations() {
    const { data, headers } = await authenticatedApi.get('/user/organizations');
    checkHeaders(headers);

    return data;
  },

  async fetchMetrics() {
    const { data, headers } = await authenticatedApi.get('/dashboard-metrics');
    checkHeaders(headers);

    return data;
  },

  async fetchBarChartData() {
    const { data, headers } = await authenticatedApi.get('/dashboard-bar-chart');
    checkHeaders(headers);

    return data;
  },

  async fetchCompanyChecks() {
    const { data, headers } = await authenticatedApi.get('/company-checks');
    checkHeaders(headers);

    return data;
  },

  async fetchOneTimeToken() {
    const { data, headers } = await authenticatedApi.post('/one-time-token');
    checkHeaders(headers);

    return data;
  },

  async fetchForms() {
    const { data, headers } = await authenticatedApi.get('/forms');
    checkHeaders(headers);

    return data;
  },

  async fetchFormStatuses() {
    const { data, headers } = await authenticatedApi.get('/form-statuses');
    checkHeaders(headers);

    return data;
  },

  async fetchJobs() {
    const { data, headers } = await authenticatedApi.get('/jobs');
    checkHeaders(headers);

    return data;
  },

  async fetchJobsMin() {
    const { data, headers } = await authenticatedApi.get('/jobs-min');
    checkHeaders(headers);

    return data;
  },

  async fetchReports() {
    const { data, headers } = await authenticatedApi.get('/reports');
    checkHeaders(headers);

    return data;
  },

  async fetchTasks() {
    const { data, headers } = await authenticatedApi.get('/tasks');
    checkHeaders(headers);

    return data;
  },

  async fetchEquipment() {
    const { data, headers } = await authenticatedApi.get('/equipment');
    checkHeaders(headers);

    return data;
  },

  async fetchHazards() {
    const { data, headers } = await authenticatedApi.get('/hazards');
    checkHeaders(headers);

    return data;
  },

  async fetchPPE() {
    const { data, headers } = await authenticatedApi.get('/ppe');
    checkHeaders(headers);

    return data;
  },

  async fetchTraining() {
    const { data, headers } = await authenticatedApi.get('/training');
    checkHeaders(headers);

    return data;
  },

  async fetchTaskAnalysis() {
    const { data, headers } = await authenticatedApi.get('/task-analysis');
    checkHeaders(headers);

    return data;
  },

  async fetchTaskAnalysisTemplates() {
    const { data, headers } = await authenticatedApi.get('/task-analysis-templates');
    checkHeaders(headers);

    return data;
  },

  async fetchFileBrowser(path) {
    const encodedPath = encodeURIComponent(path);
    const { data, headers } = await authenticatedApi.get(`/file-browser?path=${encodedPath}`);
    checkHeaders(headers);

    return data;
  },

  async fetchSettings() {
    const { data, headers } = await authenticatedApi.get(`/settings`);
    checkHeaders(headers);

    return data;
  },

  buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        formData = this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }

    return formData;
  },

  async prepareImageFormData(field) {
    if (!Array.isArray(field.files)) {
      Sentry.addBreadcrumb({
        level: 'error',
        category: 'failedImageSync',
        message: 'Field files is not an array',
        data: { files: field.files, field: field.name },
      });
      Sentry.captureException(new Error('Field files is not an array'));
      return [];
    }
    return await Promise.all(
      field.files.map(async (imageKey) => ({
        imageKey: imageKey,
        blob: await imageStore.get(imageKey),
      })),
    );
  },

  async pushReports(report) {
    for (let i = 0; i < report.actions.length; i++) {
      if (!report.actions[i].temp_id) {
        report.actions[i].temp_id = uuidv4();
      }
    }
    let formData = new FormData();
    if (!report.temp_id) {
      report['temp_id'] = uuidv4();
    }
    formData = this.buildFormData(formData, report);

    let j = 0;
    for (const field of report.form.filter((field) => field.type === 'images')) {
      if (field.files) {
        if (!Array.isArray(field.files)) {
          Sentry.addBreadcrumb({
            level: 'error',
            category: 'failedReportImageSync',
            message: 'Field files is not an array when it should be',
            data: { files: field.files, field: field.name },
          });
          Sentry.captureException(new Error('Field files is not an array'));
        } else if (field.files.length) {
          const data = await Promise.all(
            field.files.map(async (imageKey) => ({
              imageKey: imageKey,
              blob: await imageStore.get(imageKey),
            })),
          );
          for (let i = 0; i < data.length; i++) {
            try {
              formData.append(`files[${j}]`, data[i]['blob'], data[i]['imageKey']);
              j++;
            } catch (e) {
              alert('An Image upload failed, please forward all images to your admin');
              Sentry.captureException(e);
            }
          }
        }
      }
    }

    await authenticatedApi.post(`/reports`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async pushFailedSyncItems(item, type) {
    let formData = new FormData();

    this.buildFormData(formData, item, 'sync_item');
    formData.append('entity_type', type);

    let j = 0;
    if (type === types['report']) {
      for (const field of item.form.filter((field) => field.type === 'images')) {
        let data;
        if (field.files) {
          data = await this.prepareImageFormData(field);
        }
        if (data) {
          for (let i = 0; i < data.length; i++) {
            try {
              formData.append(`files[${j}]`, data[i]['blob'], data[i]['imageKey']);
            } catch (e) {
              alert('An Image upload failed, please forward all images to your admin');
              Sentry.captureException(e);
            }
            j++;
          }
        }
      }
    } else if (type === types['taskAnalysis']) {
      let data;
      if (item.files) {
        data = await this.prepareImageFormData(item);
      }
      if (data) {
        try {
          for (let i = 0; i < data.length; i++) {
            formData.append(`files[${i}]`, data[i]['blob'], data[i]['imageKey']);
          }
        } catch (e) {
          alert('An Image upload failed, please forward all images to your admin');
          Sentry.captureException(e);
        }
        data = undefined;
      }
    }

    await authenticatedApi.post(`/failed-sync-item`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async pushTasks(task) {
    let formData = new FormData();

    if (!task.temp_id) {
      task['temp_id'] = uuidv4();
    }

    formData = this.buildFormData(formData, task);

    await authenticatedApi.post(`/tasks`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async pushSettings(settings) {
    let formData = new FormData();

    formData = this.buildFormData(formData, settings);

    await authenticatedApi.post(`/settings`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async pushJobs(job) {
    let formData = new FormData();

    if (!job.temp_id) {
      job['temp_id'] = uuidv4();
    }

    formData = this.buildFormData(formData, job);

    await authenticatedApi.post(`/jobs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-PWA-ITEM-ORGANIZATION-ID': job.organization_id,
      },
    });
  },

  async pushTaskAnalysis(taskAnalysis) {
    let data;

    if (!taskAnalysis.temp_id) {
      taskAnalysis['temp_id'] = uuidv4();
    }

    if (taskAnalysis.files) {
      console.log('taskAnalysis.files', taskAnalysis.files);
      data = await Promise.all(
        taskAnalysis.files?.map(async (imageKey) => ({
          imageKey: imageKey,
          blob: await imageStore.get(imageKey),
        })),
      );
    }

    let options = {
      initialFormData: new FormData(),
      showLeafArrayIndexes: true,
      includeNullValues: true,
    };

    taskAnalysis.checks = taskAnalysis.checks?.map((check) => {
      if (typeof check === 'number' || typeof check === 'string') {
        return parseInt(check);
      }

      return parseInt(check.id);
    });

    const formData = jsonToFormData(taskAnalysis, options);

    if (data) {
      for (let i = 0; i < data.length; i++) {
        try {
          formData.append(`files[${i}]`, data[i]['blob'], data[i]['imageKey']);
        } catch (e) {
          alert('An Image upload failed, please forward all images to your admin');
          Sentry.captureException(e);
        }
      }
    }

    await authenticatedApi.post(`/task-analysis`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  async syncUsers() {
    try {
      const getUsers = async () => await this.fetchUsers();

      return getUsers().then(async (usersFromAPI) => {
        await valueStore.set(`users`, usersFromAPI);

        return usersFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`users`);
    }
  },

  async syncAttendees() {
    try {
      const getAttendees = async () => await this.fetchAttendees();

      return getAttendees().then(async (attendeesFromAPI) => {
        await valueStore.set(`attendees`, attendeesFromAPI);

        return attendeesFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`attendees`);
    }
  },

  async syncUser() {
    try {
      const getUser = async () => await this.fetchUser();

      return getUser().then(async (userFromAPI) => {
        await valueStore.set(`current-user`, userFromAPI);

        return userFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`current-user`);
    }
  },

  async syncOrganizations() {
    try {
      const getOrganizations = async () => await this.fetchOrganizations();

      return getOrganizations().then(async (organizationsFromAPI) => {
        await valueStore.set(`organizations`, organizationsFromAPI);

        return organizationsFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`organizations`);
    }
  },

  async syncDashboardMetrics() {
    try {
      const getMetrics = async () => await this.fetchMetrics();

      return getMetrics().then(async (metricsFromAPI) => {
        await valueStore.set(`dashboardMetrics`, metricsFromAPI);

        return metricsFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`dashboardMetrics`);
    }
  },

  async syncDashboardBarChart() {
    try {
      const getData = async () => await this.fetchBarChartData();

      return getData().then(async (dataFromAPI) => {
        await valueStore.set(`dashboardBarChartData`, dataFromAPI);

        return dataFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`dashboardBarChartData`);
    }
  },

  async getImageTemporaryURL(uuid, induction = false) {
    try {
      return await authenticatedApi.get('/generate-image', { params: { uuid: uuid, induction: induction } }).then((image) => {
        return image.data;
      });
    } catch (e) {
      return null;
    }
  },

  async syncCompanyChecks() {
    try {
      const getCompanyChecks = async () => await this.fetchCompanyChecks();

      return getCompanyChecks().then(async (companyChecksFromAPI) => {
        await valueStore.set(`companyChecks`, companyChecksFromAPI);

        return companyChecksFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`companyChecks`);
    }
  },

  async syncTaskAnalysis() {
    let failedSyncItems = [];
    try {
      const taskAnalysis = await valueStore.getArray(`taskAnalysis`);

      for (const ta of taskAnalysis || []) {
        if (!ta.incomplete && (!!ta.new || !!ta.updated)) {
          try {
            await this.pushTaskAnalysis(ta);
          } catch (e) {
            failedSyncItems.push({ exception: e, ta: ta });
          }
        }
      }

      const getTaskAnalysis = async () => await this.fetchTaskAnalysis();

      return getTaskAnalysis().then(async (taskAnalysisFromAPI) => {
        if (failedSyncItems.length > 0) {
          failedSyncItems = failedSyncItems.filter((item) => !taskAnalysisFromAPI?.find((ta) => ta.temp_id === item.ta.temp_id));
          for (const item of failedSyncItems) {
            Sentry.addBreadcrumb({
              level: 'error',
              category: 'syncTaskAnalysis',
              message: 'Failed to sync Task Analysis',
              data: { report: item.ta },
            });
            Sentry.captureException(item.exception, { extra: { ta: JSON.stringify(item.ta, null, 2) } });
            await this.pushFailedSyncItems(item.ta, types['taskAnalysis']);
            taskAnalysisFromAPI.push(item.ta);
          }
        }
        await valueStore.set(`taskAnalysis`, taskAnalysisFromAPI);

        return taskAnalysisFromAPI;
      });
    } catch (e) {
      Sentry.captureException(e, { extra: 'sync method failed' });
      return await valueStore.getArray(`taskAnalysis`);
    }
  },

  async syncTaskAnalysisTemplates() {
    try {
      const getTaskAnalysisTemplates = async () => await this.fetchTaskAnalysisTemplates();

      return getTaskAnalysisTemplates().then(async (taskAnalysisTemplatesFromAPI) => {
        await valueStore.set(`taskAnalysisTemplates`, taskAnalysisTemplatesFromAPI);

        return taskAnalysisTemplatesFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`taskAnalysisTemplates`);
    }
  },

  async syncForms() {
    try {
      const getForms = async () => await this.fetchForms();

      return getForms().then(async (formsFromAPI) => {
        await valueStore.set(`forms`, formsFromAPI);

        return formsFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`forms`);
    }
  },

  async syncFormStatuses() {
    try {
      const getStatuses = async () => await this.fetchFormStatuses();

      return getStatuses().then(async (formStatusesFromAPI) => {
        await valueStore.set(`formStatusesFromApi`, formStatusesFromAPI);

        return formStatusesFromAPI;
      });
    } catch (e) {
      return await valueStore.getArray(`formStatusesFromApi`);
    }
  },

  async syncJobs() {
    let failedSyncItems = [];
    try {
      const jobs = await valueStore.getArray(`jobs`);

      for (const j of (jobs || []).filter((job) => !!job.new || !!job.updated)) {
        try {
          await this.pushJobs(j);
        } catch (e) {
          failedSyncItems.push({ exception: e, job: j });
        }
      }

      const getJobs = async () => await this.fetchJobs();

      return getJobs().then(async (jobsFromAPI) => {
        if (failedSyncItems.length > 0) {
          failedSyncItems = failedSyncItems.filter((item) => !jobsFromAPI?.find((j) => j.temp_id === item.job.temp_id));
          for (const item of failedSyncItems) {
            Sentry.addBreadcrumb({
              level: 'error',
              category: 'syncJobs',
              message: 'Failed to sync Job',
              data: { job: item.job },
            });
            Sentry.captureException(item.exception, { extra: { job: JSON.stringify(item.job, null, 2) } });
            await this.pushFailedSyncItems(item.job, types['job']);
            jobsFromAPI.push(item.job);
          }
        }
        await valueStore.set(`jobs`, jobsFromAPI);

        return jobsFromAPI;
      });
    } catch (e) {
      Sentry.captureException(e, { extra: 'sync method failed' });
      return await valueStore.getArray(`jobs`);
    }
  },

  async syncJobsMin() {
    let failedSyncItems = [];
    try {
      const jobs = await valueStore.getArray(`jobs`);

      for (const j of (jobs || []).filter((job) => !!job.new || !!job.updated)) {
        try {
          await this.pushJobs(j);
        } catch (e) {
          failedSyncItems.push({ exception: e, job: j });
        }
      }

      const getJobs = async () => await this.fetchJobsMin();

      return getJobs().then(async (jobsFromAPI) => {
        if (failedSyncItems.length > 0) {
          failedSyncItems = failedSyncItems.filter((item) => !jobsFromAPI?.find((j) => j.temp_id === item.job.temp_id));
          for (const item of failedSyncItems) {
            Sentry.addBreadcrumb({
              level: 'error',
              category: 'syncJobsMin',
              message: 'Failed to sync Job min',
              data: { job: item.job },
            });
            Sentry.captureException(item.exception, { extra: { job: JSON.stringify(item.job, null, 2) } });
            await this.pushFailedSyncItems(item.job, types['job']);
            jobsFromAPI.push(item.job);
          }
        }
        await valueStore.set(`jobs`, jobsFromAPI);

        return jobsFromAPI;
      });
    } catch (e) {
      Sentry.captureException(e, { extra: 'sync method failed' });
      return await valueStore.getArray(`jobs`);
    }
  },

  async syncReports() {
    let failedSyncs = [];
    try {
      const reports = await valueStore.getArray(`reports`);
      for (const report of (reports || []).filter((report) => !!report.new || !!report.updated)) {
        try {
          await this.pushReports(report);
        } catch (e) {
          failedSyncs.push({ exception: e, report: report });
        }
      }

      const getReports = async () => await this.fetchReports();

      return getReports().then(async (reportsFromAPI) => {
        if (failedSyncs.length > 0) {
          failedSyncs = failedSyncs.filter((failedSync) => !reportsFromAPI?.find((report) => report.temp_id === failedSync.report.temp_id));
          for (const failedSync of failedSyncs) {
            Sentry.addBreadcrumb({
              level: 'error',
              category: 'syncReports',
              message: 'Failed to sync Report',
              data: { report: failedSync.report },
            });
            Sentry.captureException(failedSync.exception, { extra: { report: JSON.stringify(failedSync.report, null, 2) } });

            await this.pushFailedSyncItems(failedSync.report, types['report']);
            reportsFromAPI.push(failedSync.report);
          }
        }
        await valueStore.set(`reports`, reportsFromAPI);

        return reportsFromAPI;
      });
    } catch (e) {
      Sentry.captureException(e, { extra: 'sync method failed' });
      return await valueStore.getArray(`reports`);
    }
  },

  async syncTasks() {
    let failedSyncs = [];
    try {
      const tasks = await valueStore.getArray(`tasks`);

      for (const task of (tasks || []).filter(
        (task) => !!task.new || !!task.updated || !!task.recurring_task?.updated || task.recurring_task?.deleted,
      )) {
        try {
          await this.pushTasks(task);
        } catch (e) {
          failedSyncs.push({ exception: e, task: task });
        }
      }

      const getTasks = async () => await this.fetchTasks();

      return getTasks().then(async (tasksFromAPI) => {
        if (failedSyncs.length > 0) {
          failedSyncs = failedSyncs.filter((failedSync) => !tasksFromAPI?.find((task) => task.temp_id === failedSync.task.temp_id));
          for (const failedSync of failedSyncs) {
            Sentry.addBreadcrumb({
              level: 'error',
              category: 'syncTasks',
              message: 'Failed to sync Tasks',
              data: { task: failedSync.task },
            });
            Sentry.captureException(failedSync.exception, { extra: { task: JSON.stringify(failedSync.task, null, 2) } });
            await this.pushFailedSyncItems(failedSync.task, types['task']);
            tasksFromAPI.push(failedSync.task);
          }
        }
        await valueStore.set(`tasks`, tasksFromAPI);

        return tasksFromAPI;
      });
    } catch (e) {
      Sentry.captureException(e, { extra: 'sync method failed' });
      return await valueStore.getArray(`tasks`);
    }
  },

  async syncFileBrowser(path) {
    try {
      const getData = async () => await this.fetchFileBrowser(path);

      return getData().then(async (dataFromApi) => dataFromApi);
    } catch (e) {
      return [];
    }
  },

  async syncSettings() {
    let failedSyncs = [];

    try {
      const settings = await valueStore.get(`settings`);
      if (settings?.updated) {
        try {
          await this.pushSettings(settings);
        } catch (e) {
          failedSyncs.push({ exception: e, data: settings });
        }
      }
      const getData = async () => await this.fetchSettings();

      return getData().then(async (dataFromApi) => {
        if (failedSyncs.length > 0) {
          for (const failedSync of failedSyncs) {
            Sentry.addBreadcrumb({
              level: 'error',
              category: 'syncSettings',
              message: 'Failed to sync settings',
              data: { settings: failedSync.data },
            });
            Sentry.captureException(failedSync.exception, { extra: { task: JSON.stringify(failedSync.data, null, 2) } });
            await this.pushFailedSyncItems(failedSync.data, types['user']);
          }
        }
        await valueStore.set(`settings`, dataFromApi);

        return dataFromApi;
      });
    } catch (e) {
      Sentry.captureException(e, { extra: 'sync method failed' });
      return await valueStore.getArray(`settings`);
    }
  },
};
