import OptionalLabel from 'Components/Forms/OptionalLabel';
import { defaultInputStyles } from 'Components/Forms/TextField';
import { SelectCreate } from 'Components/SelectCreate';
import useDebounce from 'Support/hooks/useDebounce';
import { useEffect } from 'react';

const MultiField = ({ name, values, push, label, placeholder, required = false, suggestions = null }) => {
  const debouncedValues = useDebounce(values, 100);

  useEffect(() => {
    const hasEmptyItem = debouncedValues.some((value) => !value.description?.length);

    if (!hasEmptyItem) {
      push({
        description: '',
      });
    }
  }, [debouncedValues]);

  return (
    <div className="space-y-1">
      <OptionalLabel required={required} name={name} label={label} />

      {values
        .map((item, index) => (
          <SelectCreate
            key={index}
            type={name}
            options={suggestions}
            value={item['description']}
            onChange={(value) => push({ description: value })}
            className={defaultInputStyles}
          />
        ))
        .reverse()}
    </div>
  );
};

export default MultiField;
