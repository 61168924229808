import { mdiChevronDown, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import { useMemo, useRef, useState } from 'react';

export const SelectCreate = ({ onChange, options: initialOptions, value, className, type }) => {
  const inputRef = useRef<HTMLInputElement>();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [createdValue, setCreatedValue] = useState(value);
  const options = useMemo(() => {
    if (!initialOptions) return [];
    return initialOptions.filter(
      (option) => option.toLowerCase().includes(createdValue.toLowerCase()) && !value.toLowerCase().includes(option.toLowerCase()),
    );
  }, [initialOptions, createdValue]);
  return (
    <div className={cls('relative')}>
      <input
        ref={inputRef}
        placeholder={`Add new ${type}`}
        className={cls(className)}
        onChange={(e) => setCreatedValue(e.target.value)}
        value={createdValue}
        onFocus={() => setOptionsOpen(true)}
        onBlur={() => {
          setOptionsOpen(false);
        }}
      />
      <div
        className={cls(
          optionsOpen ? 'scale-y-100 ' : 'scale-y-0',
          'absolute z-50 mt-1 block w-full origin-top divide-y rounded-md border bg-white p-2 shadow transition duration-150 ease-in-out sm:text-sm sm:leading-5',
        )}
      >
        {options.length ? (
          <>
            {options.map((option, index) => (
              <div key={index} onClick={() => onChange(option)} className="py-2">
                {option}
              </div>
            ))}
            {createdValue && (
              <button
                onClick={() => {
                  onChange(createdValue);
                  setCreatedValue('');
                }}
                className="flex w-full items-center py-1"
                type="button"
              >
                <Icon size={1} path={mdiPlus} />
                Add as new {type}
              </button>
            )}
          </>
        ) : (
          <button
            onClick={() => {
              onChange(createdValue);
              setCreatedValue('');
            }}
            className="flex items-center "
            type="button"
          >
            <Icon size={1} path={mdiPlus} />
            Add as new {type}
          </button>
        )}
      </div>
      <div
        onClick={() => {
          if (!inputRef.current) return;
          inputRef?.current.focus();
        }}
        className="absolute right-2 top-0 flex h-full items-center justify-center"
      >
        <Icon path={mdiChevronDown} size={1} className={cls(optionsOpen ? 'rotate-180' : 'rotate-0', 'duration-200')} />
      </div>
    </div>
  );
};
