import useOnlineStatus from '@rehooks/online-status';
import OptionalLabel from 'Components/Forms/OptionalLabel';
import { defaultInputStyles } from 'Components/Forms/TextField.jsx';
import { SelectCreate } from 'Components/SelectCreate.tsx';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useDebounce from 'Support/hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';

const AttendeesList = ({ attendees, push, required = false }) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const debouncedAttendees = useDebounce(attendees, 100);

  useEffect(() => {
    const hasEmptyItem = debouncedAttendees.some((attendee) => !attendee);

    if (!hasEmptyItem) {
      push('');
    }
  }, [debouncedAttendees]);

  const sync = () => {
    const getAttendees = async () => await api.fetchAttendees();

    getAttendees().then(async (attendeesList) => {
      const values = attendeesList.map((attendee) => attendee.name);
      setSuggestions(values);
      await valueStore.set(`attendees`, values);
    });
  };

  const filteredSuggestions = useMemo(
    () => suggestions.filter((suggestion) => !attendees.map((attendee) => attendee?.toLowerCase()).includes(suggestion?.toLowerCase())),
    [attendees, suggestions],
  );

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      const attendees = await valueStore.getArray(`attendees`);
      setSuggestions(attendees);
    }
  }, []);

  return (
    <div className="space-y-1">
      <OptionalLabel required={required} name="attendees" label="Attendees" />

      {attendees
        .map((item, index) => (
          <SelectCreate
            key={index}
            type="attendees"
            options={filteredSuggestions}
            value={item}
            onChange={(value) => push(value)}
            className={defaultInputStyles}
          />
        ))
        .reverse()}
    </div>
  );
};

export default AttendeesList;
