import { mdiCheck, mdiClose, mdiFileUploadOutline } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import CameraModal from 'Components/CameraModal';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import ImageDisplay from 'Components/Forms/ImageDisplay';
import { defaultSelectStyles } from 'Components/Forms/ReactSelectField';
import ViewContent from 'Components/Forms/ViewContent';
import ViewLabel from 'Components/Forms/ViewLabel';
import StoredImage from 'Components/StoredImage';
import { format } from 'date-fns';
import { Field, FieldArray, FormikContext } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import imageStore from 'Services/imageStore';
import valueStore from 'Services/valueStore';
import useDebounce from 'Support/hooks/useDebounce';
import { v4 as uuidv4 } from 'uuid';

const ListItem = ({ items, fieldIndex, push, setFieldValue }) => {
  const debouncedItems = useDebounce(items, 100);
  useEffect(() => {
    const hasEmptyItem = debouncedItems?.some((item) => !item.value);
    if (!hasEmptyItem) {
      push({
        label: '',
        value: '',
        events: [],
        option_uuid: uuidv4(),
      });
    }
  }, [JSON.stringify(debouncedItems)]);

  const onChange = (newValue, index) => {
    const newItem = { ...items[index] };
    newItem.label = newValue;
    newItem.value = newValue.replace(/\s+/g, '').toLowerCase();
    items.splice(index, 1, newItem);
    setFieldValue(`form[${fieldIndex}].options`, items);
  };

  return (
    <div className="space-y-2">
      {items?.map((item, index) => (
        <Field
          key={index}
          id={index}
          placeholder="Add item"
          onChange={(e) => onChange(e.target.value, index)}
          value={String(item.label)}
          className="h-10.5 shadow-input border-1 text-blackish focus-ring focus:shadow:none w-full rounded border border-gray-300 py-2.5 pl-4 text-sm placeholder-gray-800 focus:bg-white"
        />
      ))}
    </div>
  );
};

const FieldSwitcher = ({ values, index, setFieldValue, induction }) => {
  const [cameraOpen, setCameraOpen] = useState(false);
  const [field, setField] = useState(values[index]);
  const [hazards, setHazards] = useState([]);
  const [option, setOption] = useState(null);
  const [useDefaultCamera, setUseDefaultCamera] = useState(false);
  const [options, setOptions] = useState([]);

  const defaultCameraInput = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    switch (field.link_to) {
      case 'App/Hazard':
        setOption(hazards?.find((hazard) => hazard.id === parseInt(field.value)));
        setOptions(
          hazards.map((hazard) => ({
            label: hazard.description,
            value: `${hazard.id}`,
          })),
        );
        return;
      default:
        setOptions(field.options);
        return;
    }
  }, [hazards]);

  useEffect(() => {
    setHazards(
      hazards?.map((suggestion) => ({
        ...suggestion,
        residual_risk_rating: Math.ceil(suggestion.residual_risk_rating / 5) * 5,
      })),
    );
  }, [JSON.stringify(hazards)]);

  useEffect(() => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      setUseDefaultCamera(true);
    }
  }, []);

  const formId = useContext(FormikContext).values?.id || useContext(FormikContext).values?.temp_id;

  const defaultCameraSwitch = () => {
    setCameraOpen(false);
    setUseDefaultCamera(true);
  };

  const iphoneCapture = () => {
    let blobUrl = window.URL.createObjectURL(defaultCameraInput.current.files[0]);
    let image = new Image();
    image.src = blobUrl;
    image.onload = function () {
      resizeImg(image);
    };
  };

  const resizeImg = (img) => {
    let canvas = document.createElement('canvas');
    let width = img.width;
    let maxLength = 1920;
    let percentage = 0.7;
    let height = img.height;
    if (width > height) {
      if (width > maxLength) {
        height = Math.round((height *= maxLength / width));
        width = maxLength;
      }
    } else {
      if (height > maxLength) {
        width = Math.round((width *= maxLength / height));
        height = maxLength;
      }
    }
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    canvas.toBlob(
      (blob) => {
        uploadImage(blob);
      },
      'image/jpeg',
      percentage,
    );
  };

  useEffect(async () => {
    setHazards(await valueStore.getArray(`hazards`));
  }, []);

  useEffect(() => {
    setField(values[index]);
  }, [JSON.stringify(values)]);

  const uploadImage = async (blob) => {
    const imageKey = uuidv4();
    await imageStore.set(imageKey, blob);

    const updatedValues = [...values];

    updatedValues[index].files = updatedValues[index].files || [];

    updatedValues[index].files.push(imageKey);

    setFieldValue('form', updatedValues);
  };

  const deleteImage = async (image) => {
    await imageStore.delete(image).then(() => {
      const updatedValues = [...values];

      updatedValues[index].files = updatedValues[index].files.filter((img) => img !== image);

      setFieldValue('form', updatedValues);
    });
  };

  switch (field.type) {
    case 'title':
      return <h3 className="text-xl font-bold">{field.label}</h3>;
    case 'ul':
      return (
        <>
          {formId && <ViewLabel text={field.label} />}
          {formId && (
            <ul className="ml-6 list-disc text-sm">
              {field.options?.map(({ value, label }) => (
                <li key={value}>{label}</li>
              ))}
            </ul>
          )}
          {!formId && (
            <FieldArray name={`form[${index}].options`}>
              {({ push }) => <ListItem items={field.options} fieldIndex={index} push={push} setFieldValue={setFieldValue} />}
            </FieldArray>
          )}
        </>
      );
    case 'ul-static':
      return (
        <>
          {formId && <ViewLabel text={field.label} />}
          <ul className="ml-6 list-disc text-sm">
            {field.options?.map(({ value, label }) => (
              <li key={value}>{label}</li>
            ))}
          </ul>
        </>
      );
    case 'ol':
      return (
        <>
          {formId && <ViewLabel text={field.label} />}
          {formId && (
            <ol className="ml-6 list-decimal text-sm">
              {field.options?.map(({ value, label }) => (
                <li key={value}>{label}</li>
              ))}
            </ol>
          )}
          {!formId && (
            <FieldArray name={`form[${index}].options`}>
              {({ push }) => <ListItem items={field.options} fieldIndex={index} push={push} setFieldValue={setFieldValue} />}
            </FieldArray>
          )}
        </>
      );
    case 'ol-static':
      return (
        <>
          {formId && <ViewLabel text={field.label} />}
          <ol className="ml-6 list-decimal text-sm">
            {field.options?.map(({ value, label }) => (
              <li key={value}>{label}</li>
            ))}
          </ol>
        </>
      );
    case 'checkbox':
      if (formId) {
        return (
          <FieldWrapper>
            <ViewLabel text={field.label} />
            <ViewContent
              className="col-span-2"
              text={
                <ol className="list-outside list-decimal">
                  {field.options?.map((option, index) => {
                    let checked;
                    if (option.checked !== undefined) {
                      checked = option.checked;
                    } else {
                      checked = field.value.includes(option.value);
                    }
                    return (
                      <li key={index} className="ml-1 list-none text-gray-500">
                        {checked ? (
                          <Icon className="mr-2 inline-block" path={mdiCheck} size={0.75} />
                        ) : (
                          <Icon className="mr-2 inline-block" path={mdiClose} size={0.75} />
                        )}
                        <span className="text-gray-900">{option.label}</span>
                      </li>
                    );
                  })}
                </ol>
              }
            />
          </FieldWrapper>
        );
      } else {
        return (
          <div className="space-y-5">
            {field.options?.map(({ value, label, checked }) => (
              <div key={value}>
                <div className="relative flex items-start">
                  <div className="absolute flex h-5 items-center">
                    <Field
                      value={String(value)}
                      type="checkbox"
                      name={`form[${index}].value`}
                      id={value}
                      checked={checked}
                      className="form-checkbox"
                    />
                  </div>
                  <div className="pl-7 text-sm">
                    <label className="font-medium text-gray-900" htmlFor={value}>
                      {label}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }
    case 'radio':
      if (formId) {
        return (
          <FieldWrapper>
            <ViewLabel text={field.label} />
            <ViewContent
              className="col-span-2"
              text={
                <ol className="list-outside list-decimal">
                  {field.options?.map((option, index) => {
                    let checked;
                    if (option.checked !== undefined) {
                      checked = option.checked;
                    } else {
                      checked = field.value === option.value;
                    }
                    return (
                      <li key={index} className="ml-1 list-none text-gray-500">
                        {checked ? (
                          <Icon className="mr-2 inline-block" path={mdiCheck} size={0.75} />
                        ) : (
                          <Icon className="mr-2 inline-block" path={mdiClose} size={0.75} />
                        )}
                        <span className="text-gray-900">{option.label}</span>
                      </li>
                    );
                  })}
                </ol>
              }
            />
          </FieldWrapper>
        );
      } else {
        return (
          <div className="space-y-5">
            {field.options?.map(({ value, label, checked }) => (
              <div key={value}>
                <div className="relative flex items-start">
                  <div className="absolute flex h-5 items-center">
                    <Field name={`form[${index}].value`} value={value} type="radio" id={value} checked={checked} className="form-radio" />
                  </div>
                  <div className="pl-7 text-sm">
                    <label className="font-medium text-gray-900" htmlFor={value}>
                      {label}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }
    case 'select':
      if (formId) {
        if (field.link_to !== null && field.link_to !== undefined) {
          return (
            <FieldWrapper>
              <ViewLabel text={field.label} />
              <ViewContent text={option?.description} />
            </FieldWrapper>
          );
        }
        return (
          <FieldWrapper>
            <ViewLabel text={field.label} />
            <ViewContent text={field.options?.find((option) => option.value === field.value)?.value} />
          </FieldWrapper>
        );
      } else {
        return (
          <Field
            component={Select}
            styles={defaultSelectStyles}
            instanceId={`react-select-${field.name}`}
            options={options}
            value={options?.find((option) => option.value === field.value)}
            name={`form[${index}].value`}
            onChange={(option) => setFieldValue(`form[${index}].value`, option.value)}
          />
        );
      }
    case 'textarea':
      if (formId) {
        return (
          <FieldWrapper>
            <ViewLabel text={field.label} />
            <ViewContent text={field.value} />
          </FieldWrapper>
        );
      } else {
        return (
          <Field
            className={cls(
              'block h-36 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none',
              'focus:shadow-outline-blue text-sm transition duration-150 ease-in-out focus:border-blue-300 sm:leading-5',
            )}
            name={`form[${index}].value`}
            as="textarea"
          />
        );
      }
    case 'images':
      if (formId) {
        if (Array.isArray(field.files)) {
          return (
            <FieldWrapper>
              <ViewLabel text={field.label} />
              {(field.files || []).map((image) => (
                <ImageDisplay key={image.id} uuid={image} className="h-full w-full" induction={induction} />
              ))}
            </FieldWrapper>
          );
        } else if (typeof field.files === 'object') {
          return (
            <FieldWrapper>
              <ViewLabel text={field.label} />
              {Object.values(field.files).map((image) => (
                <ImageDisplay key={image.id} media={image} className="h-full w-full" induction={induction} />
              ))}
            </FieldWrapper>
          );
        }
        return null;
      } else {
        return (
          <>
            <input className="absolute h-0 w-0 opacity-0" ref={inputRef} id="defaultCamera" type="file" accept="image/*" onChange={iphoneCapture} />
            {useDefaultCamera ? (
              <input
                className="absolute opacity-0"
                ref={defaultCameraInput}
                id="defaultCamera"
                type="file"
                accept="image/*"
                capture
                onChange={iphoneCapture}
              />
            ) : (
              <CameraModal isOpen={cameraOpen} setOpen={setCameraOpen} storePhoto={uploadImage} name="files" useDefaultCamera={defaultCameraSwitch} />
            )}

            {Array.isArray(field.files) &&
              (field.files || []).map((image) => <StoredImage key={image} image={image} deleteImage={() => deleteImage(image)} />)}

            <div className="flex gap-2">
              <PrimaryButton as="div" className="w-full" onClick={() => (useDefaultCamera ? defaultCameraInput.current.click() : setCameraOpen(true))}>
                Take Photo
              </PrimaryButton>
              <PrimaryButton type="button" onClick={() => inputRef.current.click()}>
                <Icon path={mdiFileUploadOutline} size={1} />
              </PrimaryButton>
            </div>
          </>
        );
      }

    default:
      if (formId) {
        if (field.type === 'date') {
          return (
            <FieldWrapper>
              <ViewLabel text={field.label} />
              <ViewContent text={field.value && format(Date.parse(field.value), 'dd-MM-yyy')} />
            </FieldWrapper>
          );
        } else if (field.type === 'datetime-local') {
          return (
            <FieldWrapper>
              <ViewLabel text={field.label} />
              <ViewContent text={field.value && format(Date.parse(field.value), 'dd-MM-yyyy hh:mm aaa')} />
            </FieldWrapper>
          );
        } else {
          return (
            <FieldWrapper>
              <ViewLabel text={field.label} />
              <ViewContent text={field.value} />
            </FieldWrapper>
          );
        }
      } else {
        return (
          <Field
            className={cls(
              'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none',
              'focus:shadow-outline-blue text-sm transition duration-150 ease-in-out focus:border-blue-300 sm:leading-5',
            )}
            type={field.type || ''}
            name={`form[${index}].value`}
            disabled={formId}
            value={undefined}
          />
        );
      }
  }
};

export default FieldSwitcher;
