import PrimaryButton from 'Components/Buttons/PrimaryButton';
import CameraModal from 'Components/CameraModal';
import StoredImage from 'Components/StoredImage';
import imageStore from 'Services/imageStore';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function ({ uploadedImages, setUploadedImages }) {
  const defaultCameraInput = useRef(null);
  const inputRef = useRef(null);
  const [useDefaultCamera, setUseDefaultCamera] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);

  const iphoneCapture = () => {
    if (!defaultCameraInput.current.files[0]) return;
    let blobUrl = window.URL.createObjectURL(defaultCameraInput.current.files[0]);
    let image = new Image();
    image.src = blobUrl;
    image.onload = function () {
      resizeImg(image);
    };
  };

  const defaultCameraSwitch = () => {
    setCameraOpen(false);
    setUseDefaultCamera(true);
  };

  const uploadImage = async (blob) => {
    const imageKey = uuidv4();
    await imageStore.set(imageKey, blob);
    setUploadedImages((currentState) => [...currentState, imageKey]);
  };

  const deleteImage = async (image) => {
    await imageStore.delete(image).then(() => {
      setUploadedImages((currentState) => currentState.filter((item) => item !== image));
    });
  };

  useEffect(() => {
    if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
      setUseDefaultCamera(true);
    }
  }, []);

  const resizeImg = (img) => {
    let canvas = document.createElement('canvas');
    let width = img.width;
    let maxLength = 1920;
    let percentage = 0.7;
    let height = img.height;
    if (width > height) {
      if (width > maxLength) {
        height = Math.round((height *= maxLength / width));
        width = maxLength;
      }
    } else {
      if (height > maxLength) {
        width = Math.round((width *= maxLength / height));
        height = maxLength;
      }
    }
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);
    canvas.toBlob(
      (blob) => {
        uploadImage(blob);
      },
      'image/jpeg',
      percentage,
    );
  };

  return (
    <>
      <input className="absolute h-0 w-0 opacity-0" ref={inputRef} id="defaultCamera" type="file" accept="image/*" onChange={iphoneCapture} />
      {useDefaultCamera ? (
        <input
          className="absolute opacity-0"
          ref={defaultCameraInput}
          id="defaultCamera"
          type="file"
          accept="image/*"
          capture
          onChange={iphoneCapture}
        />
      ) : (
        <CameraModal isOpen={cameraOpen} setOpen={setCameraOpen} storePhoto={uploadImage} name="files" useDefaultCamera={defaultCameraSwitch} />
      )}
      {uploadedImages.map((image) => (
        <StoredImage key={image} image={image} deleteImage={() => deleteImage(image)} />
      ))}

      <div className="flex gap-2">
        <PrimaryButton as="div" className="w-full" onClick={() => (useDefaultCamera ? defaultCameraInput.current.click() : setCameraOpen(true))}>
          Take Photo
        </PrimaryButton>
      </div>
    </>
  );
}
